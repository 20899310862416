// import axios from 'axios'
// import {Message} from 'element-ui';
// import router from '../router'
// import {mymessage} from '@/utils/mymessage';
//
// axios.interceptors.response.use(success => {
//     if (success.status && success.status === 200 && success.data.status === 500) {
//         Message.error({message: success.data.msg})
//         return;
//     }
//     if (success.status && success.status === 200 && success.data.status === 0) {
//         Message.error({message: success.data.msg})
//         return;
//     }
//     if (success.data.msg) {
//         Message.success({message: success.data.msg})
//     }
//     return success.data;
// }, error => {
//     if (error.response.status === 504 || error.response.status === 404) {
//         Message.error({message: '服务器被吃了( ╯□╰ )'})
//     } else if (error.response.status === 403) {
//         Message.error({message: '权限不足，请联系管理员'})
//     } else if (error.response.status === 401) {
//         mymessage.error({message: error.response.data.msg ? error.response.data.msg : '尚未登录，请登录'})
//         router.replace('/');
//         //Message.error({message: '权限不足，请联系管理员'})
//     } else {
//         if (error.response.data.msg) {
//             Message.error({message: error.response.data.msg})
//         } else {
//             Message.error({message: '服务器正在升级!'})
//         }
//     }
//     return;
// })
//
// let base = '/api';
//
// export const postKeyValueRequest = (url, params) => {
//     return axios({
//         method: 'post',
//         url: `${base}${url}`,
//         data: params,
//         transformRequest: [function (data) {
//             let ret = '';
//             for (let i in data) {
//                 ret += encodeURIComponent(i) + '=' + encodeURIComponent(data[i]) + '&'
//             }
//             return ret;
//         }],
//         headers: {
//             'Content-Type': 'application/x-www-form-urlencoded'
//         }
//     });
// }
// export const postRequest = (url, params) => {
//     return axios({
//         method: 'post',
//         url: `${base}${url}`,
//         data: params,
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': sessionStorage.getItem('token')
//         }
//     })
// }
// export const putRequest = (url, params) => {
//     return axios({
//         method: 'put',
//         url: `${base}${url}`,
//         data: params,
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': sessionStorage.getItem('token')
//         }
//     })
// }
// export const getRequest = (url, params) => {
//     return axios({
//         method: 'get',
//         url: `${base}${url}`,
//         params: params,
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': sessionStorage.getItem('token')
//         }
//     })
// }
// export const deleteRequest = (url, params) => {
//     return axios({
//         method: 'delete',
//         url: `${base}${url}`,
//         params: params,
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': sessionStorage.getItem('token')
//         }
//     })
// }
// export const Host='http://localhost:8080';
// // export const Host='http://localhost/api';
// // export const Host='https://develop.cctechs.cn/api';
// // export const Host='https://manufacture.cctechs.cn/api';
// // export const Host='https://test.cctechs.cn/api';
// // export const DownHost='http://localhost/';
// export const DownHost='http://localhost:8080';
// // export const DownHost='https://test.cctechs.cn';
// // export const DownHost='https://develop.cctechs.cn';
// // export const DownHost='https://manufacture.cctechs.cn';
// export const exportRequest = (url, params,fileName) => {
//         return axios({
//             method: 'get',
//             url: `${base}${url}`,
//             params: params,
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': sessionStorage.getItem('token')
//             }
//         }). then(function (response) {
//             // 创建一个 blob URL
//             const blobUrl = window.URL.createObjectURL(new Blob([response.data]))
//             const link = document.createElement('a')
//             link.href = blobUrl;
//             link.setAttribute('download', fileName); // 设置下载文件的名称
//             document.body.appendChild(link)
//             // 触发点击事件来下载文件
//             link.click();
//             // 清理
//             window.URL.revokeObjectURL(blobUrl);
//             document.body.removeChild(link);
//         }).catch(function (error) {
//             // 请求失败后的处理
//             console.error('Error downloading Excel file:', error);
//
//         })
//
//
//
// }
// 修改后的响应拦截器
import axios from 'axios'
import {Message} from 'element-ui';
import router from '../router'
import {mymessage} from '@/utils/mymessage';

axios.interceptors.response.use(success => {
    if (success.status && success.status === 200 && success.data.status === 500) {
        Message.error({message: success.data.msg})
        return;
    }
    if (success.status && success.status === 200 && success.data.status === 0) {
        Message.error({message: success.data.msg})
        return;
    }
    if (success.data.msg) {
        Message.success({message: success.data.msg})
    }
    return success.data;
}, error => {
    if (error.response.status === 504 || error.response.status === 404) {
        Message.error({message: '服务器被吃了( ╯□╰ )'})
    } else if (error.response.status === 403) {
        Message.error({message: '权限不足，请联系管理员'})
    } else if (error.response.status === 401) {
        mymessage.error({message: error.response.data.msg ? error.response.data.msg : '尚未登录，请登录'})
        router.replace('/');
        //Message.error({message: '权限不足，请联系管理员'})
    } else {
        if (error.response.data.msg) {
            Message.error({message: error.response.data.msg})
        } else {
            Message.error({message: '服务器正在升级!'})
        }
    }
    return Promise.reject(error);
})

// 添加请求拦截器
axios.interceptors.request.use(
    config => {
        // 添加token
        const token = sessionStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);


// 统一的请求配置
const defaultConfig = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('token')
    }
};
// export const Host = 'http://localhost:8080';
// export const Host = 'http://localhost/api';
export const Host = 'https://develop.cctechs.cn/api';
// export const Host = 'https://manufacture.cctechs.cn/api';
// export const Host = 'https://test.cctechs.cn/api';
// export const DownHost = 'http://localhost/';
// export const DownHost = 'http://localhost:8080';
// export const DownHost = 'https://test.cctechs.cn';
export const DownHost = 'https://develop.cctechs.cn';
// export const DownHost = 'https://manufacture.cctechs.cn';
// 简化其他请求方法
let base = '/api';
export const postRequest = (url, params) => {
    return axios({
        ...defaultConfig,
        method: 'post',
        url: `${base}${url}`,
        data: params
    });
};

export const putRequest = (url, params) => {
    return axios({
        ...defaultConfig,
        method: 'put',
        url: `${base}${url}`,
        data: params
    });
};

export const getRequest = (url, params) => {
    return axios({
        ...defaultConfig,
        method: 'get',
        url: `${base}${url}`,
        params: params
    });
};

export const deleteRequest = (url, params) => {
    return axios({
        ...defaultConfig,
        method: 'delete',
        url: `${base}${url}`,
        params: params
    });
};
// 修改导出文件的请求方法
export const exportRequest = (url, params, fileName) => {
    return axios({
        method: 'get',
        url: `${base}${url}`,
        params: params,
        responseType: 'blob', // 添加这个很重要
        headers: {
            'Authorization': sessionStorage.getItem('token')
        }
    })
        .then(response => {
            if (!response.data) {
                throw new Error('下载失败');
            }

            // 创建 blob URL
            const blob = new Blob([response.data]);
            const blobUrl = window.URL.createObjectURL(blob);

            // 创建下载链接
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = fileName;

            // 触发下载
            document.body.appendChild(link);
            link.click();

            // 清理
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);

            return response;
        })
        .catch(error => {
            console.error('下载文件失败:', error);
            Message.error({message: '下载文件失败'});
            return Promise.reject(error);
        });
};